import './ProjectsBanner.css'

export default function ProjectsBanner() {
  return (
    <div className='projects-banner'>
      <div className='banner-msg'>
        <h2>Whether on a team or on my own, I love using my creativity to <em>make</em> cool things.</h2>
      </div>
      <div className='projects-sub'>
        <h4>SEE WHAT I'VE MADE</h4>
        <h3>Projects</h3>
      </div>
    </div>
  )
}
