import { useState } from 'react'

import Contact from '../Contact/Contact' 
import AboutFooter from '../AboutFooter/AboutFooter'
import Loader from '../util/Loader/Loader'

import './About.css'

interface IProps {
  viewWidth: number
}

export default function About({ viewWidth }: IProps) {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  return (
    <div className='about'>
      <div className={imageLoaded ? 'profile img-loaded' : 'profile'}>
        {!imageLoaded && <Loader type='light' />}
        <div className='profile-img-con'>
          <img 
            src='https://avatars.githubusercontent.com/u/75702270?v=4' 
            alt='my beautiful mug'
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <div className='profile-story'>
          <h2>Hi, my name is Jon and <em>I make things</em>.</h2>
          <p>My work is most fulfilling when I’m helping to improve the lives of others by finding solutions to their day-to-day problems, driving me to build intuitive web applications that focus on providing a valuable experience for the user.</p>
        </div>
      </div>
      <Contact viewWidth={viewWidth}/>
      <AboutFooter />
    </div>
  )
}
