import './Loader.css'

interface IProps {
  type: string
}

export default function Loader({ type }: IProps) {
  return (
    <div className='loader-wrapper'>
      <div className={`loader l-${type}`}></div>
    </div>
  )
}
