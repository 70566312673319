import './Connection.css'

interface IProps {
  name: string,
  image: string,
  link: string,
  isMobile: boolean
}

export default function Connection({ name, image, link, isMobile }: IProps) {
  return (
    <a className={isMobile ? 'connection-mobile' : 'connection'}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='connect-img-con'>
        <img 
          src={`${process.env.PUBLIC_URL}/local_data/connections/assets/${image}`}
          alt={`${name} logo`}
        />
      </div>
      <h4>{name}</h4>
    </a>
  )
}
