import { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import Header from './Components/Header/Header'
import Projects from './Components/Projects/Projects'
import About from './Components/About/About'

import './App.css';

function App() {
  const [ viewWidth, setViewWidth ] = useState<number>(0)

  useEffect(() => {
    window.addEventListener('resize', () => setViewWidth(window.innerWidth))
    setViewWidth(window.innerWidth)
  }, [])

  return (
    <div className="App">
      <Header viewWidth={viewWidth}/>
      <main>
        <Route exact path='/' render={() => <Redirect to='/projects' />} />
        <Route path='/projects'>
          <Projects viewWidth={viewWidth}/>
        </Route>
        <Route path='/about'>
          <About viewWidth={viewWidth}/>
        </Route>
      </main>
    </div>
  );
}

export default App;
