import { useState, useEffect } from 'react'

import ProjectsBanner from '../ProjectsBanner/ProjectsBanner'
import Project from '../Project/Project'
import ProjectSelect from '../ProjectSelect/ProjectSelect'
import Loader from '../util/Loader/Loader'

import './Projects.css'

interface IProps {
  viewWidth: number
}

interface SingleProject {
  title: string,
  technologies: string[],
  description: string[],
  links: {
    deployment: {
      isLive: boolean,
      url: string
    }
    repository: string
  },
  assets: {
    screenshot: string,
    logo: string
  }
}

export default function Projects({ viewWidth }: IProps) {
  const [projectData, setProjectData] = useState<SingleProject[]>([])
  const [projects, setProjects] = useState<JSX.Element[]>([])
  const [currentProject, setCurrentProject] = useState<number>(0)

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/local_data/projects/projects.json`)
      .then(resp => resp.json())
      .then(projects => setProjectData(projects.data))
  }, [])

  useEffect(() => {
    setProjects(
      projectData.map((project, index) => {
        return (
          <Project
            key={project.title}
            displayType={index % 2 === 0 ? 'even' : 'odd'}
            viewWidth={viewWidth}
            project={project}
          />
        )
      })
    )
  }, [projectData, viewWidth])

  return (
    <div className='projects'>
      <ProjectsBanner />
      <div className='project-grid'>
        {!projectData.length && <Loader type='dark' />}
        {viewWidth > 768 ? projects : projects[currentProject]}
      </div>
      <ProjectSelect 
        currentProject={currentProject}
        setCurrentProject={setCurrentProject}
        logos={projectData.map(project => project.assets.logo)}
      />
    </div>
  )
}
