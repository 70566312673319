import { useState, useEffect } from 'react'

import SiteNav from '../SiteNav/SiteNav'
import './Header.css'

interface IProps {
  viewWidth: number
}

export default function Header({ viewWidth }: IProps) {
  const [scrollY, setScrollY] = useState<number>(0)

  useEffect(() => {
    document.addEventListener('scroll', () => setScrollY(window.scrollY))
  }, [])

  return (
    <header className={!scrollY ? 'app-header' : 'app-header scrolled'}>
      {viewWidth > 768 ? <h1>Jon Make Thing</h1> : <h1>JMT</h1>}
      <SiteNav isMobile={viewWidth > 460 ? false : true}/>
    </header>
  )
}
