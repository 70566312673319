import './ProjectSelect.css'

interface IProps {
  currentProject: number,
  setCurrentProject: (arg0: number) => void,
  logos: string[]
}

export default function ProjectSelect({ currentProject, setCurrentProject, logos }: IProps) {
  const projectBtns = logos.map((logo, index) => {
    return (
      <button 
        key={index}
        className={currentProject === index ? 'project-btn current' : 'project-btn'}
        onClick={() => setCurrentProject(index)}
        disabled={(currentProject === index) ? true : false}
      >
        <img
          className={currentProject === index ? 'project-logo current' : 'project-logo'} 
          src={`${process.env.PUBLIC_URL}/local_data/projects/assets/logos/${logo}`} 
          alt='current project logo' 
        />
      </button>
    )
  })

  return (
    <footer className='project-select'>
      <div className='project-btns'>
        {projectBtns}
      </div>
    </footer>
  )
}
