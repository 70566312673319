import { useState, useEffect } from 'react'
import Connection from '../Connection/Connection'
import Loader from '../util/Loader/Loader'
import './Contact.css'

interface IProps {
  viewWidth: number
}

interface SingleConnection {
  name: string,
  image_name: string,
  link: string
}

export default function Contact({ viewWidth }: IProps) {
  const [connectionData, setConnectionData] = useState<SingleConnection[]>([])

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/local_data/connections/connections.json`)
      .then(resp => resp.json())
      .then(connections => setConnectionData(connections.data))
  }, [])

  const connections = connectionData.map(connection => {
    return (
      <Connection 
        key={connection.image_name}
        name={connection.name}
        image={connection.image_name}
        link={connection.link}
        isMobile={viewWidth <= 768}
      />
    )
  })

  return (
    <div className='contact-con'>
      {!connections.length &&
        <Loader type='dark' />
      }
      {connections.length > 0 && 
        <div className='contact'>
          <div className='connection-grid'>
            <h2>See more things</h2>
            <div className='connections'>
              {connections.slice(0, 2)}
            </div>
          </div>
          <div className='connection-sep'>
            <div className='sep-line'></div>
            <h2>or</h2>
            <div className='sep-line'></div>
          </div>
          <div className='connection-grid'>
            <h2>Say hi</h2>
            <div className='connections'>
              {connections.slice(2, 4)}
            </div>
          </div>
        </div>
      }
    </div>
  )
}
