import './AboutFooter.css'

export default function Footer() {
  return (
    <footer className='about-footer'>
      <p className='author-msg'>Jon Make Thing is built and maintained by Jon (yes, that Jon).</p>
      <p className='repo-link'>See the repo <a href='https://gitlab.com/jon-schlandt/jon-make-thing' target='_blank' rel='noopener noreferrer'>here</a></p>
    </footer>
  )
}
