import { useState } from 'react'
import Loader from '../util/Loader/Loader'
import './Project.css'

interface IProps {
  displayType: string,
  viewWidth: number,
  project: {
    title: string,
    technologies: string[],
    description: string[],
    links: {
      deployment: {
        isLive: boolean,
        url: string
      }
      repository: string
    },
    assets: {
      screenshot: string,
      logo: string
    }
  }
}

export default function Project({ displayType, viewWidth, project}: IProps) {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const {assets, title, description, technologies, links} = project

  return (
    <div className={displayType === 'even' ? 'project-wrapper' : 'project-wrapper pw-odd'}>
      {!imageLoaded && <Loader type='dark' />}
      <div className={imageLoaded ? 'project img-loaded' : 'project'}>
        {(displayType === 'even' || viewWidth <= 1024) &&
          <img
            className='proj-img' 
            src={`${process.env.PUBLIC_URL}/local_data/projects/assets/images/${assets.screenshot}`}
            alt={`Main view of ${title}`}
            onLoad={() => setImageLoaded(true)}
          />
        }
        <div className='proj-interface'>
          <div className='proj-details'>
            <h3>{title}</h3>
            <p className='proj-tech'>{technologies.join(' | ')}</p>
            <ul className='proj-desc'>
              {description.map(desc => <li key={desc}>{desc}</li>)}
            </ul>
          </div>
          <div className='proj-actions'>
              <a 
                href={links.deployment.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {project.links.deployment.isLive ? "Live Demo" : "Demo"}
              </a>
              <a
                href={links.repository}
                target='_blank'
                rel='noopener noreferrer'
              >
                Repo
              </a>
          </div>
        </div>
        {(displayType === 'odd' && viewWidth > 1024) &&
          <img
            className='proj-img' 
            src={`${process.env.PUBLIC_URL}/local_data/projects/assets/images/${assets.screenshot}`}
            alt={`Main view of ${title}`}
            onLoad={() => setImageLoaded(true)}
          />
        }
      </div>
    </div>
  )
}
